<div>
    <ap-loading></ap-loading>
</div>

<div class="container" style="margin-top: 0px !important;">
    <nav class="navbar navbar-expand-lg pl-0 pr-0" [ngClass]="{ 'navbar-absolute col-lg-10 homeNav': isHome }">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent">
            <span class="navbar-toggler-icon"></span>
        </button>
        
        <div class="col-1 d-none d-md-block pl-0">
            <a [routerLink]="['']">
                <img class="img-fluid img-logo" src="/assets/img/logotipo.png" title="Home">
            </a>
        </div>

        <div class="col-4 d-none d-md-block">
            <a class="text-logo" [routerLink]="['']">AQUI TEM NUTRI</a>
        </div>

        <div class="col-7 d-block d-md-none" style="margin-left: 5px;">
            <a class="text-logo" [routerLink]="['']">AQUI TEM NUTRI</a>
        </div>

        <div class="col-2 d-block d-md-none pl-0 pr-0">
            <img class="img-fluid" src="/assets/img/logotipo.png" title="Home">
        </div>

        <div id="navbarSupportedContent" class="collapse navbar-collapse">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item mr-lg-5">
                    <a class="nav-link" [routerLink]="['']" [ngClass]="{ 'active': isHome }">Empresa</a>
                </li>
                <li class="nav-item mr-lg-5">
                    <a class="nav-link" [routerLink]="['services']" [ngClass]="{ 'active': isServices }">Serviços</a>
                </li>
                <li class="nav-item mr-lg-5">
                    <a class="nav-link" [routerLink]="['cases']" [ngClass]="{ 'active': isCases }">Cases</a>
                </li>
                <li class="nav-item mr-lg-5">
                    <a class="nav-link" [routerLink]="['materials']" [ngClass]="{ 'active': isMaterials }">Materiais</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['contact']" [ngClass]="{ 'active': isContact }">Contato</a>
                </li>
            </ul>
        </div>
    </nav>
</div>