<div class="container" style="margin-top: 15px;">
    <div class="row">
        <div class="col-lg-6" style="padding: 0;">
            <img class="img-fluid" src="/assets/img/imagemmateriais.jpg">
        </div>

        <div class="col-lg-6 primary-color-background text-center" style="display: flex; justify-content: center; align-items: center;">
            <div>
                <h3 class="title mb-2">3 estratégias para seu bar, restaurante ou padaria vencer a crise do coronavírus</h3>
                <h4 class="description" style="color: #575757;">
                    e-book gratuito
                </h4>
                <a 
                    class="btn btn-outline-secondary" 
                    href="http://mailchi.mp/a535f344b059/ebook_solucoes_estrategia_bares_restaurantes_padarias_coronavirus" 
                    target="_blank">
                    CLIQUE AQUI PARA BAIXAR
                </a>
            </div>
        </div>
    </div>
</div>