import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { RouterModule } from '@angular/router';
import { SocialModule } from '../social/social.module';
import { LoadingModule } from '../loading/loading.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        LoadingModule
    ],
    declarations: [
        HeaderComponent
    ],
    exports:[
        HeaderComponent
    ]
})
export class HeaderModule {

}