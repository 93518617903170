import { Component } from '@angular/core';

@Component({
    selector: 'atn-cases',
    templateUrl: './cases.component.html',
    styleUrls: [
        './cases.component.css'
    ]
})
export class CasesComponent {

}