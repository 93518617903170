<img class="img-fluid" src="assets/img/isasmall.jpg">

<div class="container">
    <h2>CONTATO</h2>
    <div class="section-title-separator primary-color-background"></div>

    <div class="card-deck">
        <div class="card justify-content-center" style="background-color: #ffffff;">
            <div class="card-body text-center">
                <i class="fa fa-phone fa-5x text-center primary-color"></i>
                <h5 class="card-title">Telefone</h5>
                <p class="card-text">+55 (51) 99590-4850</p>
            </div>
        </div>
        <div class="card">
            <div class="card-body text-center">
                <i class="fa fa-envelope fa-4x text-center primary-color" style="margin-top: 5px;"></i>
                <h5 class="card-title" style="margin-top: 10px;">Email</h5>
                <p class="card-text" style="font-size: 15px;">contato@aquitemnutri.com.br</p>
            </div>
        </div>
        <div class="card">
            <div class="card-body text-center">
                <i class="fa fa-comments fa-5x text-center primary-color"></i>
                <h5 class="card-title">Redes Sociais</h5>
                <p class="card-text">
                    <i class="fa fa-instagram primary-color"></i>
                    aquitemnutri
                    <br>
                    <i class="fa fa-linkedin primary-color"></i>
                    nutricionistaisabelajaeger
                </p>
            </div>
        </div>
    </div>
</div>