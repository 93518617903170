import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { LoadingService } from '../loading/loading.service';

@Component({
    selector: 'atn-header',
    templateUrl: './header.component.html',
    styleUrls: [
        './header.component.css'
    ]
})
export class HeaderComponent implements OnInit {

    isHome = false;
    isServices = false;
    isCases = false;
    isMaterials = false;
    isContact = false;

    constructor(private router: Router,
                private loadingService: LoadingService) {}

    ngOnInit() {
        this.router.events.subscribe(events => {
            if (events instanceof NavigationEnd) {
                this.loadingService.start();

                switch(events.url) {
                    case '/':
                        this.isHome = true;
                        this.isServices = false;
                        this.isCases = false;
                        this.isMaterials = false;
                        this.isContact = false;
                        break;
                    case '/services':
                        this.isHome = false;
                        this.isServices = true;
                        this.isCases = false;
                        this.isMaterials = false;
                        this.isContact = false;
                        break;
                    case '/cases':
                        this.isHome = false;
                        this.isServices = false;
                        this.isCases = true;
                        this.isMaterials = false;
                        this.isContact = false;
                        break;
                    case '/materials':
                        this.isHome = false;
                        this.isServices = false;
                        this.isCases = false;
                        this.isMaterials = true;
                        this.isContact = false;
                        break;
                    case '/contact':
                        this.isHome = false;
                        this.isServices = false;
                        this.isCases = false;
                        this.isMaterials = false;
                        this.isContact = true;
                        break;
                }
            }
        }); 
    }
}