<nav class="navbar navbar-expand navbar-light col-12 primary-color-background d-flex d-md-none mt-5 mt-lg-0">
    <ul class="navbar-nav mx-auto">
        <li class="nav-item social-icon">
            <a href="http://www.linkedin.com/in/nutricionistaisabelajaeger/" target="_blank">
                <i class="fa fa-linkedin"></i>
            </a>
        </li>
        <li class="nav-item social-icon">
            <a href="http://www.instagram.com/aquitemnutri/" target="_blank">
                <i class="fa fa-instagram"></i>
            </a>
        </li>
    </ul>
</nav>

<nav class="navbar navbar-expand navbar-light col-12 secondary-color-background mt-lg-5">
    <small class="col-lg-11 footer-text">© 2021 Aqui tem nutri. Todos os direitos reservados.</small>

    <ul class="navbar-nav d-none d-md-flex">
        <li class="nav-item social-icon">
            <a href="http://www.linkedin.com/in/nutricionistaisabelajaeger/" target="_blank">
                <i class="fa fa-linkedin"></i>
            </a>
        </li>
        <li class="nav-item social-icon">
            <a href="http://www.instagram.com/aquitemnutri/" target="_blank">
                <i class="fa fa-instagram"></i>
            </a>
        </li>
        <li class="nav-item social-icon">
            <a href="https://wa.me/5551995904850" target="_blank">
                <i class="fa fa-whatsapp"></i>
            </a>
        </li>
    </ul>
</nav>