import { Component } from '@angular/core';

@Component({
    selector: 'atn-services',
    templateUrl: './services.component.html',
    styleUrls: [
        './services.component.css'
    ]
})
export class ServicesComponent {

}