import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { HeaderModule } from './header/header.module';
import { ServicesModule } from './services/services.module';
import { CasesModule } from './cases/cases.module';
import { MaterialsModule } from './materials/materials.module';
import { ContactModule } from './contact/contact.module';
import { SocialModule } from './social/social.module';
import { HomeModule } from './home/home.module';
import { FooterModule } from './footer/footer.module';
import { OnlineMentoringModule } from './online-mentoring/online-mentoring.module';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HomeModule,
    SocialModule,
    HeaderModule,
    ServicesModule,
    CasesModule,
    MaterialsModule,
    ContactModule,
    FooterModule,
    OnlineMentoringModule
  ],
  declarations: [
    AppComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
