import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ServicesComponent } from './services/services.component';
import { CasesComponent } from './cases/cases.component';
import { MaterialsComponent } from './materials/materials.component';
import { ContactComponent } from './contact/contact.component';
import { HomeComponent } from './home/home.component';
import { OnlineMentoringComponent } from './online-mentoring/online-mentoring.component';
import { LabelingComponent } from './labeling/labeling.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent
    },
    {
        path: 'services',
        component: ServicesComponent
    },
    {
        path: 'cases',
        component: CasesComponent
    },
    {
        path: 'materials',
        component: MaterialsComponent
    },
    {
        path: 'contact',
        component: ContactComponent
    },
    {
        path: 'onlineMentoring',
        component: OnlineMentoringComponent
    },
    {
        path: 'labeling',
        component: LabelingComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {

}