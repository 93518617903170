import { Component } from '@angular/core';

@Component({
    selector: 'atn-home',
    templateUrl: './home.component.html',
    styleUrls: [
        './home.component.css'
    ]
})
export class HomeComponent {

}