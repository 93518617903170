<img class="img-fluid" src="/assets/img/homeimagesmall.jpg">

<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <h2>PROPÓSITO</h2>
            <div class="section-title-separator primary-color-background"></div>
        </div>
    </div>

    <div class="row">
        <div>
            <div class="col-12 col-lg-4 text-center proposite-item">
                <i class="fa fa-handshake-o"></i>
                <h5 class="title mt-3 mb-3">Soluções</h5>
                <p class="home-text primary-text-color">
                    Solucionar as demandas particulares à cada estrutura de negócio com 
                    transparência e proximidade. Entregar ao cliente resultados que superem suas 
                    expectativas.
                </p>
            </div>
        
            <div class="col-12 col-lg-4 text-center proposite-item">
                <i class="fa fa-check-square-o"></i>
                <h5 class="title mt-3 mb-3">Qualidade</h5>
                <p class="home-text primary-text-color">
                    Adequar seu estabelecimento à legislação sanitária vigente com base nas 
                    Boas Práticas de Manipulação de Alimentos.
                </p>
            </div>
        
            <div class="col-12 col-lg-4 text-center proposite-item">
                <i class="fa fa-thumbs-o-up"></i>
                <h5 class="title mt-3 mb-3">Diferencial</h5>
                <p class="home-text primary-text-color">
                    Impulsionar diferenciais competitivos e contribuir em aspectos que envolvem a experiência e 
                    satisfação do cliente visando a excelência. 
                </p>
            </div>
        </div>
    </div>
</div>

<div class="col-12 primary-color-background text-center" style="padding-top: 20px; padding-bottom: 20px; margin-top: 60px;">
    <h2 style="color: white; margin-top: 20px; font-weight: bold;">
        Mentoria online para empresários e empreendedores do ramo de alimentos.
        <br>
        Tire suas dúvidas e cresça seu negócio!
    </h2>

    <div class="d-none d-md-flex" style="justify-content: center;">
        <a 
            class="btn btn-light"
            style="margin-top: 20px; color: grey; font-weight: bold;"
            href="https://web.whatsapp.com/send?phone=5551995904850" 
            target="_blank">
            <i id="whatsAppIcon" class="fa fa-whatsapp" style="vertical-align: middle; margin-right: 5px;"></i>
            Entre em contato agora mesmo!
        </a>
    </div>

    <div class="d-md-none">
        <a 
            class="btn btn-light"
            style="margin-top: 20px; color: grey; font-weight: bold;"
            href="https://wa.me/5551995904850" 
            target="_blank">
            <i id="whatsAppIcon" class="fa fa-whatsapp" style="vertical-align: middle; margin-right: 5px;"></i>
            Entre em contato agora mesmo!
        </a>
    </div>

    <a class="onlineMentoringLink" href="onlineMentoring">Clique aqui para saber mais!</a>
</div>

<div class="container">
    <div class="col-12 text-center">
        <div>
            <h2>PRAZER, <br>
                AQUI TEM NUTRI!</h2>
            <div class="section-title-separator primary-color-background"></div>
        </div>
    </div>

    <div class="d-md-none">
        <div class="row mt-3">
            <div class="col-12 text-center">
                <img class="img-fluid home-img" src="/assets/img/IMG_0895small.jpg">
            </div>
        </div>
        
        <div class="row mt-3">
            <div class="col-12">
                <p class="home-text text-justify primary-text-color">
                    A empresa de consultoria e assessoria é formada pela nutricionista Isabela Jaeger que atua em diversos tipos de 
                    estabelecimentos como cafeterias, padarias, restaurantes, pizzarias e hamburguerias. Implanta as Boas Práticas 
                    de Manipulação de Alimentos, aplica ferramentas de gestão e promove resultados positivo no seu negócio de 
                    alimentação!
                </p>
            </div>
        </div>
    </div>

    <div class="row d-none d-md-flex">
        <div class="col-4 text-center">
            <img class="img-fluid home-img" src="/assets/img/IMG_0895small.jpg">
        </div>

        <div class="col-4 text-center">
            <span class="home-text my-auto primary-text-color">
                A empresa de consultoria e assessoria é formada pela nutricionista Isabela Jaeger que atua em diversos tipos de 
                estabelecimentos como cafeterias, padarias, restaurantes, pizzarias e hamburguerias. Implanta as Boas Práticas de Manipulação de Alimentos, aplica ferramentas de gestão e promove resultados positivo no seu negócio de alimentação!
            </span>
        </div>

        <div class="col-4 text-center">
            <img class="img-fluid home-img" src="/assets/img/IMG_0850small.jpg">
        </div>
    </div>
</div>