import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CasesComponent } from './cases.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        CasesComponent
    ]
})
export class CasesModule {

}