<nav class="navbar navbar-expand navbar-light bg-white green" style="border-bottom: 0px solid; padding-bottom: unset; padding-top: unset;">
    <div class="ml-auto">
        <ul class="navbar-nav">
            <li class="nav-item">
                <a class="nav-link" href="https://www.instagram.com/aquitemnutri/" target="_blank">
                    <i class="fa fa-instagram fa-lg primary-color"></i>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="https://www.linkedin.com/in/nutricionistaisabelajaeger/" target="_blank">
                    <i class="fa fa-linkedin fa-lg primary-color"></i>
                </a>
            </li>
        </ul>
    </div>
</nav>