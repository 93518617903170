<img class="img-fluid" src="assets/img/IMG_0829small.jpg">

<div class="container">
    <h2>Cases</h2>
    <div class="section-title-separator primary-color-background"></div>

    <div class="card-deck">
        <div class="card">
            <div class="card-body text-center">
                <img class="img-fluid img-testimonial" src="assets/img/bigpao.jpg">
                <h5 class="title mt-2 mb-2">Big Pão</h5>
                <h5 class="subtitle">Padaria, Confeitaria e Restaurante</h5>
            </div>
        </div>
        <div class="card">
            <div class="card-body text-center">
                <img class="img-fluid img-testimonial" src="assets/img/ciadosabor.jpg" style="border-radius: 30%;">
                <h5 class="title mt-2 mb-2">Cia do sabor</h5>
                <h5 class="subtitle">Pizzaria</h5>
            </div>
        </div>
        <div class="card">
            <div class="card-body text-center">
                <img class="img-fluid img-testimonial" src="assets/img/cdx.jpg">
                <h5 class="title mt-2 mb-2">CDX</h5>
                <h5 class="subtitle">Lanches variados</h5>
            </div>
        </div>
        <div class="card">
            <div class="card-body text-center">
                <img class="img-fluid img-testimonial" src="assets/img/miski.png">
                <h5 class="title mt-2 mb-2">Miski</h5>
                <h5 class="subtitle">Sorvetes, Café e Hamburguer</h5>
            </div>
        </div>
    </div>

    <div class="card-deck mt-3">
        <div class="card">
            <div class="card-body text-center">
                <img class="img-fluid img-testimonial" src="assets/img/baunilha.jpeg">
                <h5 class="title mt-2 mb-2">Baunilha</h5>
                <h5 class="subtitle">Biscoitos Personalizados</h5>
            </div>
        </div>
        <div class="card">
            <div class="card-body text-center">
                <img class="img-fluid img-testimonial" src="assets/img/cmec.jpg">
                <h5 class="title mt-2 mb-2">CMEC</h5>
                <h5 class="subtitle">Escola Privada de Educação Infantil ao Ensino Médio</h5>
            </div>
        </div>
        <div class="card">
            <div class="card-body text-center">
                <img class="img-fluid img-testimonial" src="assets/img/hoffmann.png">
                <h5 class="title mt-2 mb-2">Hoffmann</h5>
                <h5 class="subtitle">Indústria de Congelados</h5>
            </div>
        </div>
        <div class="card">
            <div class="card-body text-center">
                <img class="img-fluid img-testimonial" src="assets/img/pereira.png">
                <h5 class="title mt-2 mb-2">Super Pereira</h5>
                <h5 class="subtitle">Supermercado, Padaria e Açougue</h5>
            </div>
        </div>
        <div class="card">
            <div class="card-body text-center">
                <img class="img-fluid img-testimonial" src="assets/img/braun.png">
                <h5 class="title mt-2 mb-2">Panifício Braun</h5>
                <h5 class="subtitle">Padaria e Confeitaria</h5>
            </div>
        </div>
    </div>
</div>
