<div class="primary-color-background" style="color: white;">
    <div class="container" style="margin-top: 0; padding-top: 20px; padding-bottom: 20px;">
        <div class="row col-12 justify-content-center">
            <h2 style="font-weight: bold;">
                Rotulagem de Alimentos
            </h2>
        </div>
    
        <div class="row col-12 justify-content-center" style="margin-top: 0px;">
            <iframe width="840" height="474.5" src="https://www.youtube.com/embed/RqiA5wYVr_c" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen class="mt-3"></iframe>
        </div>
    
        <div class="row">
            <div class="col-4 text-center d-none d-md-flex">
                <img class="img-fluid home-img" src="/assets/img/imagemrotulo.jpg">
            </div>
    
            <div class="col-12 col-lg-8 my-auto">
                <h3 class="text-center">Nova Rotulagem de Alimentos</h3>
                <span style="display: flex; color: white; text-align: justify;">
                    A rotulagem de alimentos é obrigatória para qualquer alimento produzido, embalado e comercializado na ausência do consumidor.
                    A nova rotulagem de alimentos concede o prazo até dia 9 de outubro de 2022 para a reformulação dos rótulos. A nova rotulagem frontal dos alimentos necessita de algumas mudanças nos rótulos existentes, é necessário reformular o rótulo do seu produto? Lembre-se de se organizar previamente para comunicar o fornecedor da embalagem para te entregar a tempo!
                </span>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-lg-8 my-auto">
                <h3 class="text-center">Importância da Rotulagem</h3>
            
                <ul style="text-align: justify;">
                    <li>
                        <i class="fa fa-check"></i> O rótulo é o primeiro contato com o cliente e a rotulagem valoriza o produto.
                    </li>
                    <li>
                        <i class="fa fa-check"></i> Informar o consumidor sobre a composição do produto prazo de validade/lote, procedência, informações necessárias como por exemplo: modo de preparo, conservação e a quantidade total do produto.
                    </li>
                </ul>
            
                <h3 id="secondTitle" class="text-center">Vantagens de ter rótulo no seu produto</h3>

                <ul style="text-align: justify;">
                    <li>
                        <i class="fa fa-check"></i> Oportunidades de vendas para grande varejos que exigem rótulos e procedência legalizada.
                    </li>
                    <li>
                        <i class="fa fa-check"></i> Aumento da vendas para o consumidor final - O cliente entende o compromisso que a empresa assume com o consumidor, mostrando clareza e transparência com o rótulo.
                    </li>
                    <li>
                        <i class="fa fa-check"></i> Transmite valores de confiança e segurança ao cliente do produto que está comprando para comercializar ou consumir.
                    </li>
                </ul>
            </div>

            <div class="col-4 text-center d-none d-md-flex">
                <img class="img-fluid home-img" src="/assets/img/IMG_0873small.jpg">
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h3 class="text-center">Papel do @aquitemnutri na Rotulagem</h3>
                <span>
                    Atuamos como parceiro de pequenas e médias empresas e empreendedores das áreas de alimentos, agregando valor, credibilidade ao seu produto através da rotulagem nutricional e <a href="services" style="color: white;"><u>outros serviços</u></a>. Fornecemos desde à informação nutricional até a rotulagem completa. Entre em contato!
                </span>
            </div>
        </div>

        <div class="row d-none d-md-flex" style="margin-top: 0px; justify-content: center;">
            <a 
                class="btn btn-light"
                style="margin-top: 20px; color: grey; font-weight: bold;"
                href="https://web.whatsapp.com/send?phone=5551995904850" 
                target="_blank">
                <i id="whatsAppIcon" class="fa fa-whatsapp" style="color: #60ba62; vertical-align: middle; margin-right: 5px;"></i>
                Quero um orçamento!
            </a>
        </div>
    
        <div class="row d-md-none" style="margin-top: 0px; justify-content: center;">
            <a 
                class="btn btn-light"
                style="margin-top: 20px; color: grey; font-weight: bold;"
                href="https://wa.me/5551995904850" 
                target="_blank">
                <i id="whatsAppIcon" class="fa fa-whatsapp" style="color: #60ba62; vertical-align: middle; margin-right: 5px;"></i>
                Quero um orçamento!
            </a>
        </div>
    </div>
</div>