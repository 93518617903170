<div class="col-12 primary-color-background text-center" style="padding-top: 20px; padding-bottom: 20px;">
    <h2 style="color: white; margin-top: 20px; font-weight: bold;">
        Mentoria online para empresários e empreendedores do ramo de alimentos.
    </h2>

    <ul class="col-lg-8" style="margin-left: auto; margin-right: auto;">
        <li>
            <span class="question">Esta mentoria online é para mim?</span>
            <span class="answer">Sim, se você for um empresário ou empreendedor do ramo de alimentos. A mentoria vai te auxiliar a tomar as melhores decisões na condução do seu serviço de alimentação, seja ele um bar, restaurante, padaria, pizzaria, hamburgueria ou demais tipos de estabelecimentos.</span>
        </li>
        <li>
            <span class="question">Como isso pode me ajudar?</span>
            <span class="answer">
                <b>Vigilância Sanitária:</b> Muitos empresários se deparam com o desafio de implantar diversos processos em seu estabelecimento para obter a liberação do alvará sanitário.
                    Este é um processo realmente desafiador, sendo necessário um grande entendimento das solicitações e documentações. Não se preocupe, podemos te ajudar com isso!
                    Na mentoria tiramos todas as suas dúvidas com relação a este processo e, conforme necessidade, organizamos a documentação e padronizamos os processos necessários mediante visitas ao local.
                
                <br>
                <br>
                <b>Rotulagem:</b> A legislação de rótulos foi atualizada e você precisa atualizar o rótulo do seu produto já existente?
                Pretende lançar produtos novos no mercado e precisa de um profissional para realizar a rotulagem segundo das normas da ANVISA?
                 Precisa saber como funciona para realizar o cadastro do seu produto na ANVISA? Isso é realmente necessário? Essas dúvidas são muito comuns e através da mentoria online conseguimos te responder a todas elas, facilitando assim o seu entendimento sobre a lesgislação de rotulagem vigente.
                <br>
                <br>
                <b>Treinamentos:</b> Sua equipe está desmotivada? Desperdiça muitos produtos e insumos da produção? Seu estabelecimento precisa de padrão nos produtos ofertados ao cliente? Estas são questões fundamentais e que precisam estar solucionadas para que um estabelecimento de alimentação obtenha sucesso. Através de treinamentos e capacitações com a equipe podemos melhorar estes aspectos. Através da mentoria te explico melhor como podemos aplicar as ferramentas existentes na gestão do teu negócio.
                <br>
                <br>
                Além disso, ainda fornecemos diversos outros serviços, você pode conferir mais <a href="services" style="color: white;"><u>clicando aqui.</u></a>
            </span>
        </li>
        <li>
            <span class="question">Quais as vantagens?</span>
            <ul style="text-align: left; color: white; margin-left: -40px;">
                <li>
                    <i class="fa fa-check"></i> Estruturar o seu estabelecimento frente à legislação sanitária
                </li>
                <li>
                    <i class="fa fa-check"></i> Reduzir custos com insumos e produtos
                </li>
                <li>
                    <i class="fa fa-check"></i> Aumentar a lucratividade
                </li>
                <li>
                    <i class="fa fa-check"></i> Reduzir o desperdício
                </li>
                <li>
                    <i class="fa fa-check"></i> Diminuir a rotatividade da sua equipe
                </li>
                <li>
                    <i class="fa fa-check"></i> Padronizar os processos do seu estabelecimento
                </li>
            </ul>
        </li>
        <li>
            <span class="question">Como funciona?</span>
            <span class="answer">Será agendada uma reunião virtual pela plataforma Google Meet, Zoom ou Whatsapp Web.</span>
        </li>
        <li>
            <span class="question">Qual o valor do investimento?</span>
            <span class="answer">O investimento para uma reunião é de R$97,00. A forma de pagamento pode ser escolhida entre Pix, Transferência Bancária ou Boleto.</span>
        </li>
        <li>
            <span class="question">Em caso de fechamento de contrato para execução de um serviço, posso abater o valor investido na mentoria online?</span>
            <span class="answer">Sim, fique tranquilo, já pensamos nisso! Em caso de fecharmos um contrato de serviço (rotulagem, treinamentos ou demais serviços), o valor investido na mentoria online será descontado do valor total do contrato.</span>
        </li>
    </ul>

    <div class="d-none d-md-flex" style="justify-content: center;">
        <a 
            class="btn btn-light"
            style="margin-top: 20px; color: grey; font-weight: bold;"
            href="https://web.whatsapp.com/send?phone=5551995904850" 
            target="_blank">
            <i id="whatsAppIcon" class="fa fa-whatsapp" style="color: #60ba62; vertical-align: middle; margin-right: 5px;"></i>
            Quero agendar minha mentoria online!
        </a>
    </div>

    <div class="d-md-none">
        <a 
            class="btn btn-light"
            style="margin-top: 20px; color: grey; font-weight: bold;"
            href="https://wa.me/5551995904850" 
            target="_blank">
            <i id="whatsAppIcon" class="fa fa-whatsapp" style="color: #60ba62; vertical-align: middle; margin-right: 5px;"></i>
            Quero agendar minha mentoria online!
        </a>
    </div>
</div>