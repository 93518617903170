import { Component } from '@angular/core';

@Component({
    selector: 'atn-online-mentoring',
    templateUrl: './online-mentoring.component.html',
    styleUrls: [
        './online-mentoring.component.css'
    ]
})
export class OnlineMentoringComponent {

}