import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OnlineMentoringComponent } from './online-mentoring.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        OnlineMentoringComponent
    ]
})
export class OnlineMentoringModule {

}