import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialsComponent } from "./materials.component";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        MaterialsComponent
    ]
})
export class MaterialsModule {}