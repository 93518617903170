<img class="img-fluid" src="assets/img/IMG_0816small.jpg">

<div class="services-section" style="margin-top: 60px;">
    <div class="inner-width">
        <h2>SERVIÇOS</h2>
        <div class="section-title-separator primary-color-background"></div>
        <div class="services-container">
            <div class="row">
                <div class="service-box col-12 col-md-6 col-lg-4">
                    <div class="service-icon">
                        <i class="fa fa-check-square-o"></i>
                    </div>
                    <h6 class="title mb-2">Consultoria, Assessoria e Auditoria</h6>
                    <p class="description primary-text-color">
                        Serviços realizados por meio de visitas nos estabelecimentos com aplicação de listas de verificações e relatórios com apontamentos sobre o diagnóstico situacional do estabelecimento visitado.
                    </p>
                </div>

                <div class="service-box col-12 col-md-6 col-lg-4">
                    <a class="service-icon" href="onlineMentoring">
                        <i class="fa fa-lightbulb-o"></i>
                    </a>
                    <h6 class="title mb-2">Mentoria Online</h6>
                    <p class="description primary-text-color">
                        Atendimento online através da plataforma Google Meet para auxiliar o empreendedor do ramo de alimentos nas melhores decisões
                        para o seu negócio.
                    </p>
                </div>

                <div class="service-box col-12 col-md-6 col-lg-4">
                    <div class="service-icon">
                        <i class="fa fa-line-chart"></i>
                    </div>
                    <h6 class="title mb-2">Gestão do Negócio</h6>
                    <p class="description primary-text-color">
                        Fornecimento de planilhas, manuais e orientações para facilitar o crescimento da sua empresa, dentre eles, controle de custo e desperdícios.
                    </p>
                </div>

                <div class="service-box col-12 col-md-6 col-lg-4">
                    <div class="service-icon">
                        <i class="fa fa-file-text-o"></i>
                    </div>
                    <h6 class="title mb-2">Manual de Boas práticas e POP's</h6>
                    <p class="description primary-text-color">
                        Documentos exigidos pelas inspetorias sanitárias de cada município para estabelecimentos que comercializam alimentos e bebidas. Refletem a realidade de cada setor e serviço prestado.
                    </p>
                </div>

                <div class="service-box col-12 col-md-6 col-lg-4">
                    <div class="service-icon">
                        <i class="fa fa-users"></i>
                    </div>
                    <h6 class="title mb-2">Treinamentos e Capacitações</h6>
                    <p class="description primary-text-color">
                        Ferramentas que tornam os manipuladores de alimentos do seu estabelecimento aptos a garantir a segurança dos alimentos produzidos e visam a excelência do trabalho por eles executado.
                    </p>
                </div>
    
                <div class="service-box col-12 col-md-6 col-lg-4">
                    <a class="service-icon" href="labeling">
                        <i class="fa fa-ticket"></i>
                    </a>
                    <h6 class="title mb-2">Rotulagem Nutricional</h6>
                    <p class="description primary-text-color">
                        Descrição obrigatória que auxilia o consumidor sobre as propriedades nutricionais do alimento ou produto e os seus ingredientes, conforme preconizado pela legislação vigente.
                    </p>
                </div>
    
                <div class="service-box col-12 col-md-6 col-lg-4">
                    <div class="service-icon">
                        <i class="fa fa-pencil-square-o"></i>
                    </div>
                    <h6 class="title mb-2">Ficha Técnica e Receituário</h6>
                    <p class="description primary-text-color">
                        Ferramentas essenciais para o sucesso de cardápios. Garantem a padronização de receitas, do produto e eficiência do trabalho executado no estabelecimento.
                    </p>
                </div>

                <div class="service-box offset-lg-4 col-12 col-md-6 col-lg-4">
                    <div class="service-icon">
                        <i class="fa fa-cutlery"></i>
                    </div>
                    <h6 class="title mb-2">Suporte em Eventos</h6>
                    <p class="description primary-text-color">
                        Acompanhamento do seu evento, auxílio na promoção do controle e a execução dos procedimentos corretos aos fornecedores e manipuladores a fim de minimizar qualquer risco ao seu evento.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>